import { gql } from "@apollo/client";
export const Apply_CheckoutDetails = gql`
  query ApplyCheckoutDetails(
    $checkoutEntity: String!
    $checkoutId: ID!
    $applyRewardPoints: Int!
  ) {
    ApplyCheckoutDetails(
      checkoutEntity: $checkoutEntity
      checkoutId: $checkoutId
      applyRewardPoints: $applyRewardPoints
    ) {
      meta {
        status
        message
        code
      }
      order_total
      apply_reward_points
      total_reward_value
      total_used_points
      remaining_amount_to_pay
      total_vat_value
      formatted_total_vat_value
    }
  }
`;

export const Get_RewardPointsHistories = gql`
  query GetRewardPointsHistories($first: Int!, $page: Int!,$type:RewardHistoryType!) {
    GetRewardPointsHistories(first: $first, page: $page,type:$type) {
      data{
        formatted_created_at
        movement
        total_points
        no_of_items
        order{
          id
          serial_no
          placed_at
          order_address {
            full_address
          }
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }`;

export const Find_CMS = gql`
  query FindCMS($type: CmsTypes!) {
    FindCMS(type: $type) {
      id
      title
      content
    }
  }
`;
