import { gql } from "@apollo/client";
export const Get_ProductDetails = gql`
  query GetProductDetails($productId: Int!) {
    GetProductDetails(productId: $productId){
      id
      name
      slug
      sub_title
      is_available
      price{
        price
      }
      product_variant {
        type
        id
        has_rewards
        reward_points
        goodies_points
        applicable_reward_points
      }
      #apply_reward_points
      checkout_details {
        sub_total
        formatted_sub_total
        total
        formatted_total
        total_discount
        formatted_total_discount
        total_shipping_charges
        formatted_total_shipping_charges
        total_discount_price
        formatted_total_discount_price
        total_reward_points
        formatted_total_reward_points
        total_reward_value
        formatted_total_reward_value
        total_applied_goodies_points
        formatted_total_applied_goodies_points
        total_reward_points
        total_reward_points_label
        remaining_amount_to_pay
        formatted_remaining_amount_to_pay
        total_earned_points
        formatted_total_earned_points
        total_used_points
        formatted_total_used_points
        total_selling_price
        formatted_total_selling_price
        min_required_quantity
        total_vat_value
        formatted_total_vat_value
      }
      description
      short_description
      warranty_details
      specifications
      more_details
      formatted_price
      original_price
      in_wishlist
      thumbnail{
        name
        path
      }
      images{
        path
      }
      documents
						{
        path
      }
      categories{
        name
      }
      offer{
        type
        value
        active
        label
      }
      reviews{
        comment
        pin_to_homepage
        ratings
        created_at
        user {
          name
          email
          avatar
        }
      }
      cart_line {
        quantity
      }
      cart_details {
        min_limit
        adjust_count
        max_limit
      }
    }
    user {
      name
      is_suspended
      total_reward_points
      total_reward_value
    }
  }`;

export const Get_ProductDetailsBySlug = gql`
  query GetProductDetailsBySlug($slug: String!) {
    GetProductDetailsBySlug(slug: $slug){
      id
      name
      slug
      sub_title
      is_available
      price{
        price
      }
      product_variant {
        type
        id
        has_rewards
        reward_points
        goodies_points
        applicable_reward_points
      }
      #apply_reward_points
      checkout_details {
        sub_total
        total_selling_price
        total
        total_discount
        total_shipping_charges
        total_discount_price
        total_reward_points
        total_reward_value
        total_earned_points
        total_applied_goodies_points
        #total_applied_goodies_amount
        min_required_quantity
      }
      description
      short_description
      warranty_details
      specifications
      more_details
      formatted_price
      original_price
      in_wishlist
      thumbnail{
        name
        path
      }
      images{
        path
      }
      documents {
        id
        path
        formattedData{
          size
          mime_type
          created_at
          client_original
        }
        created_at
        type
        client_original
        name
      }
      categories{
        name
      }
      offer{
        type
        value
        active
        label
      }
      reviews{
        comment
        pin_to_homepage
        ratings
        created_at
        user {
          name
          email
          avatar
        }
      }
      cart_line {
        quantity
      }
      cart_details {
        min_limit
        adjust_count
        max_limit
      }
    }
    user {
      name
      is_suspended
      total_reward_points
      total_reward_value
    }
  }`;

export const GET_RandomProducts = gql`
  query GetRandomProducts($first: Int!, $page: Int!) {
    GetRandomProducts(first: $first, page: $page) {
      data {
        id
        name
        slug
        sub_title
        formatted_price
        in_wishlist
        is_new
        is_available
        price {
          price
        }
        description
        warranty_details
        specifications
        more_details
        formatted_price
        thumbnail {
          name
          path
        }
        product_variant {
          type
          id
          has_rewards
          reward_points
          goodies_points
          applicable_reward_points
        }
        images {
          path
        }
        documents {
          path
        }
        categories {
          id
          name
        }
        offer {
          type
          value
          active
          label
        }
        reviews {
          comment
          ratings
          user {
            avatar
          }
        }
        cart_line {
          quantity
        }
        cart_details {
          min_limit
          adjust_count
          max_limit
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }`;

export const GET_SimilarProducts = gql`
  query GetSimilarProducts($first: Int!, $page: Int!, $slug: String!){
    GetSimilarProducts(first: $first, page: $page,slug: $slug) {
      data{
        id
        name
        slug
        sub_title
        formatted_price
        in_wishlist
        is_new
        is_available
        price{
          price
        }
        description
        warranty_details
        specifications
        more_details
        formatted_price
        thumbnail{
          name
          path
        }
        product_variant {
          type
          id
          has_rewards
          reward_points
          goodies_points
          applicable_reward_points
        }
        images{
          path
        }
        documents
            {
          path
        }
        categories{
          id
          name
        }
        offer{
          type
          value
          active
          label
        }
        reviews{
          comment
          ratings
          user{
            avatar
          }
        }
        cart_line {
          quantity
        }
        cart_details {
          min_limit
          adjust_count
          max_limit
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }`;

export const Get_RegisteredProducts = gql`
  query GetRegisteredProducts($first: Int!, $page: Int!, $str: String) {
    GetRegisteredProducts(first: $first, page: $page, str: $str) {
      data {
        id
        serial_no
        name
        date_of_purchase
        created_at
        register_date
        #user {
        #  id
        #  name
        #}
        category {
          id
          name
        }
        product {
          id
          name
        }
        order {
          id
        }
        status
        feedback
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`;
